import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { City, Country, GlobalDataMetrics, Settings, State, SystemStatistics } from '.';
import { CrudApiResponse, GlobalConfiguration, PageablePayload } from '../../shared';

@Injectable({
    providedIn: 'root'
})
export class SystemStatisticsService {

    constructor(public config: GlobalConfiguration, public http: HttpClient) { }

    public getStatistics(): Observable<CrudApiResponse<SystemStatistics>> {
        return this.http.get<CrudApiResponse<SystemStatistics>>(this.config.getAPIUrl('/system/statistics/envirnoment'))
            .pipe(map(result => {
                return result;
            }));
    }

    public getDataMetrics() : Observable<PageablePayload<GlobalDataMetrics>> {
        return this.http.get<PageablePayload<GlobalDataMetrics>>(this.config.getAPIUrl("/system/statistics/global"))
            .pipe(map(result => {
                return result;
            }));
    }

}

