import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Settings } from '.';
import { GlobalConfiguration } from '../../shared';

@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    constructor(public config: GlobalConfiguration, public http: HttpClient) { }

    public setFeatures(): Observable<boolean> {
        return this.http.get<Settings>(
            this.config.getAPIUrl('system/features')).pipe(map(result => {
                window.TB_FEATURES = result;
                return true;
            }));
    }

    public isEnabled( entry: string ) {
        return window.TB_FEATURES[entry] == true;
    }

    public getCurrentFeatures(category: string = "all") {
        if (category == "all") {
            return window.TB_FEATURES;
        }
        return window.TB_FEATURES[category];
    }

}

