import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListEntry, Settings } from '.';
import { GlobalConfiguration, PageablePayload } from '../../shared';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(public config: GlobalConfiguration, public http: HttpClient) { }

    public setGlobalSettings(): Observable<boolean> {
        return this.http.get<Settings>(this.config.getAPIUrl('/system/settings'))
            .pipe(map(result => {
                window.TB_SETTINGS = result;
                return true;
            }));
    }

    public getCurrentSettings(): Settings {
        return window.TB_SETTINGS as Settings;
    }

    public getParameters(parameter_group: string): Observable<PageablePayload<ListEntry>> {
        return this.http.get<PageablePayload<ListEntry>>(this.config.getAPIUrl('/system/parameters/' + parameter_group))
            .pipe(map(result => {
                return result;
            }));
    };
}



